<template>
  <div class="side-menu" :class="{ opened: sideMenu }">
    <div class="side-menu_button" @click="on">
      <img :src="require('@/assets/image/side-menu/side-menu.png')" alt="" />
    </div>
    <div class="side-menu_holder">
      <div @click="sideMenu = false" class="close">
      <img :src="require('@/assets/image/side-menu/dotts.svg')" alt="">
      </div>
      <ul @click="sideMenu = false" class="side-menu_nav">
        <li><a class="primary-h1" href="#hero-screen">Демо</a></li>
        <li><a class="primary-h1" href="#modules">Модули</a></li>
        <li><a class="primary-h1" href="#our-work">Разработка</a></li>
        <li><a class="primary-h1" href="#clients">Клиенты</a></li>
      </ul>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      sideMenu: false,
    };
  },
  methods: {
    on() {
      this.sideMenu = true;
    },
  },
};
</script>
<style lang="scss">
.side-menu {
  position: fixed;
  display: flex;
  top: 0;
  right: -100%;
  z-index: 1031;
  transition: all 0.5s ease-in;
}
.side-menu_holder {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  box-sizing: border-box;
  padding: 55px 0px 0px 43px;
  background-color: $primary;
}

.opened {
  right: 0%;
}

.close {
  width: 30px;
  height: 30px;
}

.side-menu_button {
  width: fit-content;
  height: fit-content;
}

.side-menu_nav {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  list-style-type: none;
  margin-top: 160px;
  height: 40%;
  > li > a {
    text-decoration: none;
    color: $white;
  }
}
</style>
