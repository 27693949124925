import { createStore } from 'vuex'
export default createStore({
  state: {
    offset: true,
    popup: false,
    modulesStage: 0,
    block: [{ name: '#hero-screen', state: false }, { name: '#modules', state: false }, { name: '#banner', state: false }, { name: '#our-work', state: false }, { name: '#solution', state: false }, { name: '#about', state: false }, { name: '#clients', state: false }, { name: '#entry', state: false },],
  },
  getters: {
    getPopup(state) {
      return state.popup
    },
    getModuleStage(state) {
      return state.modulesStage
    },
    getBlockState(state){
      return state.block
    },
    getOffsetScrolled(state){
      return state.offset
    }
  },
  mutations: {
    togglePopup(state) {
      state.popup = !state.popup
    },
    changeMS(state, i) {
      state.modulesStage = i
    },
    getBlock(state, r){
      for(let item in state.block){
        if (state.block[item].name === r){
          state.block[item].state = true
        }
      }
    },
    changeOffset(state, scrolled){
      state.offset = scrolled
    }
  },
  actions: {

  },
  modules: {
  }
})
