<template>
  <footer class="container">
    <div class="row justify-content-md-between justify-content-center">
      <div class="col-12 d-flex justify-content-center d-md-block mb-3">
        <img :src="require('@/assets/main-logo/medici-logo.svg')" alt="" />
      </div>
      <div class="col-12 col-md-5 d-flex flex-column">
        <p class="body-3 text-center text-md-start"><b>ООО «МедичиСофт</b>»</p>
        <p class="body-3 text-center text-md-start m-0 mb-2">
          Россия, г Новосибирск
          
        </p>
        <p class="body-3 text-center text-md-start m-0 mb-4">Телефон: <a href="tel:+79833116332">+7(983)-311-6332</a></p>
        <p class="body-3 text-center text-md-start m-0">
          <a href="/file/politic_and_confidential_rules.pdf">Правила политики конфиденциальности</a>
        </p>
      </div>
      <div class="contacts col-sm-6 col-12 col-md-3 d-flex flex-column align-items-center">
        <div
          class="d-flex align-items-center justify-content-around justify-content-md-end mb-3"
        >
          <p class="body-3 m-0">apply@medicisoft.ru</p>
          <img class="ms-2" :src="require('@/assets/image/icons/mail.svg')" alt="" />
        </div>
        <div
        >
          <a class="d-flex align-items-center justify-content-around justify-content-md-end tg-link body-3" href="https://t.me/mismedici">Telegram-канал
          <img class="ms-2" :src="require('@/assets/image/icons/tg.svg')" alt="" /></a>
        </div>
      </div>
    </div>
  </footer>
</template>
<style lang="scss">
footer {
  display: flex;
  align-items: center;
  height: 230px;
  margin-top: 50px;

  .tg-link {
    text-decoration: none;
    color: $black;
  }
  @media screen and (min-width: 576px) {
    .row {
      width: 100%;
    }
  }
  @media screen and (max-width: 576px) {
    .contacts > div {
      width: 100%;
    }
  }
}
</style>
