<template>
  <header>
    <img :src="require('@/assets/main-logo/medici-logo.svg')" alt="" />
    <div class="container-lg container-fluid">
      <div
        class="row position-relative justify-content-between align-items-center"
      >
        <div class="col-md-3 col-6 header__logo">
          <img
            :src="require('@/assets/main-logo/medici-logo.svg')"
            alt=""
          />
        </div>
        <div class="col d-none d-md-block d-xl-none"></div>
        <div class="col-5 d-none d-md-block">
          <ul class="navigation">
            <li class="menu-item">
              <a @click="isClicked" ref="menuItem1" class="menu-link" href="#hero-screen">Демо</a>
            </li>
            <li class="menu-item">
              <a @click="isClicked" ref="menuItem2" class="menu-link" href="#modules">Модули</a>
            </li>
            <li class="menu-item d-none">
              <a  ref="menuItem3" class="menu-link" href="#banner"></a>
            </li>
            <li class="menu-item">
              <a @click="isClicked" ref="menuItem4" class="menu-link" href="#our-work">Разработка</a>
            </li>
            <li class="menu-item d-none">
              <a ref="menuItem5" class="menu-link" href="#solution"></a>
            </li>
            <li class="menu-item d-none">
              <a ref="menuItem6" class="menu-link" href="#about"></a>
            </li>
            <li class="menu-item">
              <a @click="isClicked" ref="menuItem7" class="menu-link" href="#clients">Клиенты</a>
            </li>
            <li class="menu-item d-none">
              <a ref="menuItem8" class="menu-link" href="#entry"></a>
            </li>
          </ul>
        </div>
        <div class="col-6 col-md-3 d-flex" style="height: fit-content">
          <var-button @click="togglePopup" outlined smallbutton class="me-4 me-md-0 ms-auto"
            >Оставить заявку</var-button
          >
        </div>
      </div>
    </div>
  </header>
</template>
<script>
import VarButton from "../button/VarButton.vue";
import { mapMutations } from "vuex";

export default {
  components: { VarButton },
  methods: {
    ...mapMutations(["togglePopup", "getBlock", "changeOffset"]),
    onClassChange(classAttrValue, val, newHash) {
      const classList = classAttrValue.split(" ");
      classList.push(val);
      if (classList.includes("active")) {
        this.getBlock(newHash);
      }
    },
    isClicked(){
      this.changeOffset(false);
      setTimeout(()=>{
        this.changeOffset(true);}, 300
      )
    }
  },
  mounted() {
    this.observer = new MutationObserver((mutations) => {
      for (const m of mutations) {
        const newValue = m.target.getAttribute(m.attributeName);
        const newHash = m.target.hash;
        this.$nextTick(() => {
          this.onClassChange(newValue, m.oldValue, newHash);
        });
      }
    });
    for (let item in this.$refs) {
      this.observer.observe(this.$refs[item], {
        attributes: true,
        attributeOldValue: true,
        attributeFilter: ["class"],
      });
    }
  },
  beforeUnmount() {
    this.observer.disconnect();
  },
};
</script>
<style lang="scss">
header {
  display: flex;
  position: fixed;
  align-items: center;
  max-width: 100vw;
  height: 125px;
  background-color: $white;
  // background-image: url("@/assets/main-logo/medici-logo.svg");
  background-repeat: no-repeat;
  background-size: (300px 46px);
  background-position: (2%, center);
  > img {
    position: absolute;
    left: 40px;
  }
}

.header__logo {
  display: none;
}

@media screen and (max-width: 2000px) {
  header > img {
    display: none;
  }
  .header__logo {
    display: block;
    >img {
      width: 100%;
    }
  }
}

.navigation {
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: 100%;
  width: 100%;
  margin: 0px;
  list-style-type: none;
}
</style>
