import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import "bootstrap/dist/css/bootstrap.min.css"
import "bootstrap"
import { registerScrollSpy } from 'vue3-scroll-spy'
import '@/assets/styles/styles.scss'
import '@/assets/media-styles/mobile.scss'
import axios from 'axios'
import VueAxios from 'vue-axios'


createApp(App).use(VueAxios, axios).use(store).use(registerScrollSpy).use(router).mount('#app')
