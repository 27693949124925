<template>
  <div>
    <div class="company-tabs-wrapper">
      <button
        @click="changeTab(i)"
        v-for="(tab, i) in tabs"
        class="tab company-tabs"
        :class="{ current: i == currentTab }"
        :style="[
          { '--iconUrl': `${'url(' + tab.icon + ')'}` },
          { '--btnWidth': `${tab.width}` + 'px' },
        ]"
        :key="i"
        style="
          ::before {
            width: 100%;
          }
        "
      ></button>
    </div>
    <div class="company-tabs__info">
          <div class="company-tabs__descr">
            <Transition name="list"  mode="out-in"  >
            <p :key="tabs[currentTab].descr" v-html="tabs[currentTab].descr" />
            </Transition>
            <Transition name="list" mode="out-in">
            </Transition>
          </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    tabs: {
      type: [Array, Object],
      default() {},
    },
  },
  data() {
    return {
      currentTab: 0,
    };
  },
  methods: {
    changeTab(name) {
      this.currentTab = name;
    },
  },
};
</script>
<style lang="scss">
@mixin sm-block() {
  @media screen and (max-width: 767px) {
    @content;
  }
}
.tab {
  background: #fff;
  padding: 12px 20px;
  display: flex;
  align-items: center;
  border-radius: 8px;
  border: 2px solid $primary;
  border-bottom-width: 4px;
  transition: all 0.5s ease;
}
.company-tabs {
  width: var(--btnWidth);
  height: 52px;
  &:hover,
  &.current {
    background: $primary;
    &::before {
      background-color: #fff;
      background-image: none;
      mask-image: var(--iconUrl);
      -webkit-mask-image: var(--iconUrl);
      mask-repeat: no-repeat;
      -webkit-mask-repeat: no-repeat;
      mask-size: contain;
      -webkit-mask-size: contain;
    }
  }
  &::before {
    content: "";
    display: block;
    height: auto;
    width: 100%;
    height: 100%;
    background-image: var(--iconUrl);
    background-repeat: no-repeat;
    background-size: contain;
  }
  & + & {
    margin-left: 26px;
  }
  &-wrapper {
    display: flex;
    margin-bottom: 42px;
  }
  &__wrapper {
    position: relative;    
  }
  &__descr {
    font-family: "Neue";
    font-size: 22px;
    font-style: normal;
    font-weight: normal;
    line-height: 139%;
  }
  &__info {
    position: relative;
    &-link {
      display: flex;
      align-items: center;
      width: fit-content;
      font-size: 22px;
      font-family: "Neue";
      font-style: normal;
      font-weight: normal;
      line-height: 139%;
      text-decoration: none;
      color: $primary;
      &:hover,
      &:active {
        color: $primary;
      }
      &::after {
        content: "";
        display: block;
        width: 23px;
        height: 23px;
        margin-left: 9px;
        background-image: url("@/assets/image/icons/link-icon.svg");
      }
    }
  }
  @include sm-block {
    width: 100%;
    display: flex;
    justify-content: center;
    &-wrapper {
      flex-direction: column;
      margin-bottom: 20px;
    }
    & + & {
      margin-left: 0;
      margin-top: 20px;
    }
    &::before {
      background-position: center;
    }
    &:hover,
    &.current {
      &::before {
        mask-position: center;
        -webkit-mask-position: center;
      }
    }
  }
}

.list-enter-active,
.list-leave-active {
  transition: all 0.5s ease;
}
.list-enter-from,
.list-leave-to {
  opacity: 0;
}
</style>
