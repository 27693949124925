<template>
  <div class="card">
    <div class="row align-items-center">
      <div class="col-5 col-xl-6"><img class="card-icon" :src="card.media" alt="" /></div>
      <div class="col-7 col-xl-6">
        <h4 class="primary-h4 hov primary-color">{{ card.header }}</h4>
      </div>
    </div>
    <p class="mt-2 hov primary-font">{{ card.text }}</p>
  </div>
</template>
<script>
export default {
  props: {
    card: {
      type: Object,
      default() {},
    },
  },
};
</script>
<style lang="scss">
.card {
  width: 100%;
  min-height: 245px;
  max-height: 245px;
  box-sizing: border-box;
  padding: 44px 15px 22px 15px;
  background-color: $white;
  box-shadow: $primary-shadow;
  transition: all 0.3s ease-in-out;
  cursor: pointer;
}
.card-icon {
  width: 100%;
  height: 100px;
}

.card:hover {
  background-color: $primary;
  > .row>.col-7>.hov,
  .hov {
    color: $white;
    transition: all 0.3s ease-in-out;
  }
}
</style>
