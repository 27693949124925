<template>
  <get-header
    v-scroll-spy-active="{ selector: 'a.menu-link', class: 'active' }"
    v-scroll-spy-link="{ selector: 'a.menu-link' }"
    class="fixed-top"
  />
  <MobileMenu class="d-md-none" />
  <main
    ref="main"
    v-scroll-spy="{
      allowNoActive: true,
      offset: getOffsetScrolled ? 400 + offset : offset,
    }"
  >
    <section
      id="hero-screen"
      :class="{ 'opacity-1': getBlockState[0].state }"
      class="hero-screen container"
    >
      <div class="row align-items-center">
        <div class="col-12 col-xl-6">
          <h1 class="primary-h1 text-center text-xl-start">
            Медицинская <br />
            <span class="primary-color">экосистема</span> <br />
            будущего
          </h1>
          <p class="primary-font dark-text m-0 d-none d-xl-block">
            Сделайте работу своей клиники прозрачной и управляемой, с помощью MEDICI –
            медицинской экосистемы будущего!
            <br />
            <br />
            Увеличьте выручку клиники до 50%, сэкономив время врачей и администраторов
          </p>
          <div class="hero-screen_actions">
            <div class="row">
              <div
                class="col-12 col-xl-6 d-flex flex-column d-xl-block justify-content-center"
              >
                <var-button @click="togglePopup" largebutton filled
                  >Записаться на презентацию</var-button
                >
                <p class="low-font grey-text text-center">~ 30 минут в Online-формате</p>
              </div>
              <div class="d-block d-xl-none">
                <iframe
                  width="390"
                  height="215"
                  src="https://www.youtube.com/embed/8nRmYP6WUOI"
                  title="YouTube video player"
                  frameborder="0"
                  allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                  allowfullscreen
                ></iframe>
              </div>
            </div>
          </div>
          <p class="primary-font dark-text m-0 d-xl-none">
            Сделайте работу своей клиники прозрачной и управляемой, с помощью MEDICI –
            медицинской экосистемы будущего!
            <br />
            <br />
            Увеличьте выручку клиники до 50%, сэкономив время врачей и администраторов
          </p>
        </div>
        <div class="col-6 d-none d-xl-block">
          <iframe
            width="620"
            height="380"
            src="https://www.youtube.com/embed/8nRmYP6WUOI"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowfullscreen
          ></iframe>
        </div>
      </div>
    </section>
    <section
      :class="{ 'opacity-1': getBlockState[1].state }"
      id="modules"
      class="modules container"
    >
      <div class="row">
        <div class="col-12 col-xl-6 position-relative">
          <span class="losso"></span>
          <h2 class="primary-h2 primary-color mb-3">ТОП 7 МОДУЛЕЙ</h2>
          <p class="primary-font m-0">
            платформы MEDICI, которые помогут клинике работать эффективнее
          </p>
        </div>
      </div>
      <div class="modules-choose">
        <div class="row">
          <div class="col-12 d-flex flex-wrap">
            <div class="row">
              <div v-for="(item, i) in modules" :key="i" class="col-12 wfc col-md-4 mb-4">
                <VarButton
                  :idStage="i"
                  largebutton
                  fwm
                  filled
                  :noactive="i != getModuleStage"
                  >{{ item.name }}
                </VarButton>
              </div>
            </div>
          </div>
        </div>
      </div>
      <p class="label m-0">Модуль</p>
      <div class="modules-view">
        <template v-for="(items, i) in modules" :key="i">
          <transition name="fade" mode="out-in">
            <div v-show="i === getModuleStage" class="row">
              <div class="col-xl-6 col-12">
                <h2 class="secondary-h2 mb-xl-2 mb-4 mt-4">{{ items.name }}</h2>
                <ul class="ps-3">
                  <template v-for="item in items.li" :key="item.i">
                    <li
                      :class="item.indexOf(' - ') == 0 ? 'list' : ''"
                      class="primary-font"
                    >
                      {{ item }}
                    </li>
                  </template>
                </ul>
              </div>
              <div class="col-xl-6 col-12">
                <div
                  :class="items.contain ? 'contain' : ''"
                  class="media-holder modules-view-media"
                >
                  <img :src="items.media" alt="" />
                </div>
              </div>
            </div>
          </transition>
        </template>
        <div class="robot">
          <img :src="require('@/assets/image/robot/robot.png')" alt="" />
        </div>
      </div>
    </section>
    <section :class="{ 'opacity-1': getBlockState[2].state }" id="banner" class="banner">
      <div class="container">
        <div class="row">
          <div class="col-12 col-xl-10">
            <h2 class="primary-h2 white-text text-center text-xl-start">
              Ещё <span class="accent-color">65+</span> модулей и функциональных
              возможностей
            </h2>
          </div>
          <div class="col-12 pt-4">
            <h3 class="primary-h3 normal-m white-text text-center text-xl-start">
              Готовые решения в экосистеме Medici помогают ускорить работу всех
              сотрудников в 2-3 раза и оптимизировать большую часть задач клиники
            </h3>
          </div>
          <div class="col-12 d-xl-none pt-5">
            <h4 class="primary-h4 white-text text-center text-xl-start">
              Запишитесь на бесплатную презентацию и узнайте больше о возможностях
              экосистемы
            </h4>
          </div>
          <div
            class="col-xl-3 col-12 d-flex flex-column d-xl-block align-items-center pt-5"
          >
            <var-button @click="togglePopup" largebutton accentbutton fwm
              >Записаться на презентацию</var-button
            >
            <p class="low-font white-text text-center">~ 30 минут в Online-формате</p>
          </div>
          <div class="col-8 d-none d-xl-block pt-5">
            <h4 class="primary-h4 white-text text-center text-xl-start">
              Запишитесь на бесплатную презентацию и узнайте больше о возможностях
              экосистемы
            </h4>
          </div>
        </div>
      </div>
    </section>
    <section
      :class="{ 'opacity-1': getBlockState[3].state }"
      id="our-work"
      class="cloud-solution container"
    >
      <div class="row align-items-center">
        <div class="col-6 d-none d-md-block position-relative" style="height: 550px">
          <div class="media-holder cloud-media">
            <img :src="require('@/assets/image/safety-cloud.png')" alt="" />
          </div>
        </div>
        <div class="col-12 col-md-6">
          <p class="label m-0">Наша разработка</p>
          <h2 class="secondary-h2"><strong>Безопасное</strong> облачное решение</h2>
          <div class="d-flex align-items-center cloud-solution_desc">
            <img :src="require('@/assets/image/icons/data-protection.svg')" alt="" />

            <p class="ps-2 primary-font">
              Используем защищенные каналы связи, обеспечивая
              <strong>безопасность</strong> ваших данных
            </p>
          </div>
          <div class="d-flex align-items-center cloud-solution_desc">
            <img :src="require('@/assets/image/icons/certs.svg')" alt="" />

            <p class="ps-2 primary-font">
              Вся система построена на
              <strong>лицензированных в России</strong> программных модулях
            </p>
          </div>
          <div class="d-flex align-items-center cloud-solution_desc">
            <img :src="require('@/assets/image/icons/sanc-protection.svg')" alt="" />

            <p class="ps-2 primary-font">
              <strong>Не беспокойтесь</strong> о санкциях или о каких-либо ограничениях на
              использование извне
            </p>
          </div>
        </div>
      </div>
    </section>
    <section
      :class="{ 'opacity-1': getBlockState[4].state }"
      id="solution"
      class="cloud-solution-banner container"
    >
      <div class="row">
        <div class="col-12 col-xl-7">
          <h2 class="primary-h2 text-center text-md-start">
            <span class="primary-color">Облачное</span> решение для <br />
            вашей клиники
          </h2>
          <p class="primary-font text-center text-md-start dark-text2">
            Используйте облачное решение, и не тратьте время и финансы на
            администрирование своего сервера <br /><br />
            При необходимости наши специалисты помогут расширить функциональность, как в
            облачном, так и в коробочном решении
          </p>
        </div>
      </div>
      <div class="cloud-img d-block">
        <img :src="require('@/assets/image/cloud-media.svg')" alt="" />
      </div>
    </section>
    <div class="company container" id="company">
      <h2 class="secondary-h2">Интеграции</h2>
      <CompanyTab :tabs="companies"/>
    </div>
    <section
      :class="{ 'opacity-1': getBlockState[5].state }"
      id="about"
      class="about container"
    >
      <p class="label m-0">О нас</p>
      <h2 class="secondary-h2">
        <span class="primary-color strong-text">MEDICI</span> — необходимый помощник
        руководителя и собственника клиники
      </h2>
      <div class="row">
        <div v-for="(card, i) in about" :key="i" class="col-12 col-md-6 col-xl-4 p-2">
          <get-card :card="card" />
        </div>
      </div>
    </section>
    <section
      :class="{ 'opacity-1': getBlockState[6].state }"
      id="clients"
      class="clients"
    >
      <div class="container">
        <p class="label m-0">Наши клиенты</p>
        <div class="row justify-content-between">
          <div class="col-12 col-xl-4">
            <h1 class="attention-h2">БОЛЕЕ <span class="primary-color">150</span></h1>
            <p class="primary-font">
              клиентов по всему миру доверяют нам! <br /><br />
              Мы успешно сотрудничаем как с частными клиниками, так и с учреждениями
              здравоохранения федерального и международного уровня, в том числе на
              территории стран СНГ.
            </p>
          </div>
          <div class="col-12 col-xl-7 foo">
            <h2 class="attention-h2">
              Работаем <span class="primary-color">с 2014</span> года
            </h2>
            <p class="primary-font">
              И повышаем эффективность клиник <strong>более, чем в 2 раза</strong>.
              <br /><br />
              Разрабатываем модули под конкретные потребностиклиник и осуществляем
              оперативную техническую поддержку.
            </p>
          </div>
        </div>
      </div>
      <get-carousel />
    </section>
    <section :class="{ 'opacity-1': getBlockState[7].state }" id="entry" class="entry">
      <div class="container">
        <div class="row">
          <div class="col-12 col-xl-8 d-flex flex-column justify-content-between">
            <h1 class="primary-h1 white-text mb-3">
              Запишитесь на <br />
              <span class="accent-color">бесплатную</span> презентацию
            </h1>
            <p class="body-2 white-text mt-4 mb-4 m-xl-0">
              и узнайте больше о возможностях и тарифах экосистемы MEDICI
            </p>
            <div class="form">
              <div class="form__row">
                <EntryInput
                  v-model:entryValue="newClient.email"
                  @update:entryValue="newClient.email = $event"
                  whitetext
                  placeholder="example@mail.ru"
                  type="email"
                  id="email"
                  >Адрес электронной почты</EntryInput
                >
                <EntryInput
                  v-model:entryValue="newClient.phone"
                  @update:entryValue="newClient.phone = $event"
                  whitetext
                  placeholder="+7 (999) 999 9999"
                  type="phone"
                  id="phone"
                  maska="+7 (###) ### ####"
                  >Телефон</EntryInput
                >
              </div>
              <div class="form__row">
                <EntryInput
                  v-model:entryValue="newClient.company"
                  @update:entryValue="newClient.company = $event"
                  whitetext
                  placeholder="ООО СибирьАптека"
                  type="name"
                  id="company"
                  >Название компании</EntryInput
                >
                <EntryInput
                  v-model:entryValue="newClient.name"
                  @update:entryValue="newClient.name = $event"
                  whitetext
                  placeholder="Иван"
                  type="text"
                  id="username"
                  >Контактное лицо (имя)</EntryInput
                >
              </div>
            </div>
            <div class="mt-3 m-xl-0">
              <VarButton
                @click="sendUser"
                largebutton
                accentbutton
                :wait="wait"
                :success="success"
                fw
                :disabled="disableState"
                class="mb-4"
              >
                <span v-if="!(wait || success)">Записаться на презентацию</span>
              </VarButton>
              <p v-if="error" :class="[{ error: error }]" class="mb-4">
                {{ errorText }}
              </p>
              <p class="low-font white-text">
                * Нажимая на кнопку, вы подтверждаете, что ознакомились с политикой по
                обработке персональных данных и даете согласие на обработку персональных
                данных
              </p>
            </div>
          </div>
          <div class="d-none d-xl-block col-4">
            <div class="entry-media media-holder">
              <img
                style="width: auto; height: 100%"
                :src="require('@/assets/image/company/team-photo.jpg')"
                alt=""
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
  <get-footer />
  <transition name="fade"><get-popup v-if="getPopup" /></transition>
</template>
<script>
import GetHeader from "@/components/header/GetHeader.vue";
import VarButton from "@/components/button/VarButton.vue";
import GetCard from "@/components/cards/GetCard.vue";
import EntryInput from "@/components/inputs/EntryInput.vue";
import GetPopup from "@/components/popup/GetPopup.vue";
import CompanyTab from "../../components/company-tabs/CompanyTab.vue";
import { mapGetters, mapMutations } from "vuex";
import GetFooter from "@/components/footer/GetFooter.vue";
import GetCarousel from "@/components/carousel/GetCarousel.vue";
import MobileMenu from "@/components/header/MobileMenu.vue";
import sendMail from "@/API/mailRequest";
import checkEmail from "@/helpers/checkValidity";
import { vMaska } from "maska";
export default {
  components: {
    GetHeader,
    VarButton,
    GetCard,
    EntryInput,
    GetPopup,
    GetFooter,
    GetCarousel,
    MobileMenu,
    CompanyTab
  },
  directives: { maska: vMaska },
  data() {
    return {
      about: [
        {
          media: require("@/assets/image/icons/card-icon/control.svg"),
          header: "Удаленный контроль",
          text:
            "Возможность дистанционно контролировать работу клиники, находясь в другом городе или стране",
        },
        {
          media: require("@/assets/image/icons/card-icon/dostup.svg"),
          header: "Разделение на филиалы",
          text:
            "Возможность разделения по филиалам для анализа эффективности работы и финансовых показателей",
        },
        {
          media: require("@/assets/image/icons/card-icon/marketing.svg"),
          header: "Маркетинг",
          text:
            "Возможность анализа эффективности в разрезе разных продуктов, услуг, среднего чека, допродаж и т.д.",
        },
        {
          media: require("@/assets/image/icons/card-icon/ip-phone.svg"),
          header: "Интеграция с телефонией",
          text:
            "Определение номера, запись разговоров, аудит качества работы сотрудников регистратуры",
        },
        {
          media: require("@/assets/image/icons/card-icon/ychet.svg"),
          header: "Учёт склада",
          text:
            "Позволяет более эффективно управлять остатками/затратами на мед.препараты и расходные материалы",
        },
        {
          media: require("@/assets/image/icons/card-icon/analitic.svg"),
          header: "Расширенная аналитика",
          text:
            "Возможность формирования отчётов по итогам месяца. Принимайте решения для повышения эффективности клинки",
        },
      ],
      modules: [
        {
          name: "Рабочее место врача",
          li: [
            "Увеличьте поток пациентов без необходимости заполнения бумажной документации",

            "Упростите работу врача с готовыми шаблонами заполнения медицинских заключений",

            "Ускорьте заполнение медицинских документов, за счет поддержки работы с МКБ-10",
          ],
          media: require("@/assets/image/modules-preview/workplace.png"),
        },

        {
          name: "Профосмотры и чекапы",
          li: [
            "Модуль соответствует Приказу 29н",
            "Увеличивает проходимость клиники на 80% за счет электронной очереди",
            "Снижает время пребывания пациента в клинике, без потери качества обследования",
          ],
          media: require("@/assets/image/modules-preview/chekup.png"),
        },
        {
          name: "Регистратура",
          li: [
            "Увеличьте поток пациентов с помощью электронной очереди",

            "Записывайте пациентов онлайн на своем сайте и оповещайте с помощью SMS",

            "Упростите работу врачам и администраторам",
          ],
          media: require("@/assets/image/modules-preview/resep.png"),
        },

        {
          name: "Электронная очередь",
          li: [
            "Существенно облегчает работу администраторов и менеджеров клиники.",
            "Избавляет клинику от очередей, распределяя поток пациентов при оказании медицинской помощи пациентам.",
            "Позволяет автоматически формировать статистические данные по списку очередей",
          ],
          media: require("@/assets/image/modules-preview/queue.png"),
          contain: true,
        },
        {
          name: "Электронная медицинская карта",
          li: [
            "Лечите эффективнее, за счет хранения истории болезни и диагностических результатов",
            "Быстрее находите всю информацию о пациенте",
            "Делайте назначения или выписывайте рецепты быстрее, с помощью интеллектуальных справочников",
          ],
          media: require("@/assets/image/modules-preview/medcard.png"),
        },

        {
          name: "Интеграция с клинико-диагностическими лабораториями",
          li: [
            "Получайте результаты исследований в мед. карте пациента в Live-режиме с интеграцией таких лабораторий, как: Invitro, СитиЛаб и другие.",
            "Обеспечьте автоматизированное рабочее место лаборанту КДЛ и процедурному кабинету",
          ],
          media: require("@/assets/image/modules-preview/labint.png"),
        },
        {
          name: "Автоматизация ОМС и ДМС",
          li: [
            "Избавьтесь от проблем в обмене информацией с:",
            " - ФОМС",
            " - Страховыми организациями",
            " - Медицинской организацией",
            "Оказывайте платные услуги пациентам с ОМС и ДМС",
          ],

          media: require("@/assets/image/modules-preview/dms.png"),
        },
      ],
      newClient: {
        name: "",
        company: "",
        email: "",
        phone: "",
      },
      success: false,
      error: false,
      wait: false,
      errorText: "",
      companies: [
        {name: 'mango-Office', descr:'Обеспечьте непрерывную связь с&nbsp;вашими пациентами. Интегрируйтесь с&nbsp;MangoOffice для управления звонками и&nbsp;сообщениями, делая вашу клинику более доступной и&nbsp;клиентоориентированной.<br><br><a class="company-tabs__info-link" target="_blank" href="https://www.mango-office.ru/products/virtualnaya_ats/?p=400003135" >Подробнее</a>', icon: require('../../../public/logos/MangoOffice.svg'), width: '173', link: 'https://www.mango-office.ru/products/virtualnaya_ats/?p=400003135'},
        {name: 'invitro', descr:'Сотрудничество с&nbsp;сетью клиник анализов Invitro делает процесс диагностики и&nbsp;лабораторных исследований быстрым и&nbsp;эффективным. Мы помогаем вам интегрироваться с Invitro, чтобы пациенты получали результаты быстрее и&nbsp;без лишних хлопот.', icon: require('../../../public/logos/invitro.svg'), width: '168', link: ''},
        {name: '1c', descr:'1C - ваш надежный партнер для&nbsp;учета финансов, формирования отчетов и&nbsp;других важных бизнес-процессов. Наши интеграции с 1C обеспечивают вам возможность более эффективно управлять вашей клиникой. Упростите учет финансов, улучшите точность отчетов и&nbsp;обеспечьте надежную работу всех аспектов вашего бизнеса. С&nbsp;нами, ваша клиника будет функционировать на&nbsp;высшем уровне эффективности.', icon: require('../../../public/logos/1C_Company_logo.svg'), width: '90', link: ''},
        {name: 'pro-doctorov', descr:'Продокторов - Наши интеграции позволяют вам быстро предоставлять вашим клиентам информацию о&nbsp;специалистах и&nbsp;облегчают процесс записи. С&nbsp;нашей помощью ваша клиника станет более доступной и&nbsp;удобной для&nbsp;пациентов, что&nbsp;приведет к&nbsp;повышению удовлетворенности и&nbsp;верности вашим услугам.', icon: require('../../../public/logos/logo_prodoctorov.svg'), width: '298', link: ''},
      ]
    };
  },

  methods: {
    ...mapMutations(["togglePopup"]),
    sendUser() {
      var client = {};
      client.name = this.newClient.name;
      client.company = this.newClient.company;
      client.phone = this.newClient.phone;
      client.email = this.newClient.email;

      this.errorText = "";
      if (client.name && client.company && client.phone && client.email) {
        if (checkEmail(client.email)) {
          this.wait = true;
          sendMail(client)
            .then((data) => {
              if (data.status == 200) {
                this.success = true;
              } else {
                this.error = true;
                this.errorText =
                  "При отправке письма произошла ошибка. Пожалуйста, проверьте e-mail и повторите ещё раз.";
              }
            })
            .then(() => {
              if (this.success && typeof window.ym !== "undefined") {
                window.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, "reachGoal", "sendForm");
              }
            })
            .catch(() => {
              this.error = true;
              this.errorText =
                "Произошла ошибка отправки данных. Пожалуйста, обновите страницу или попробуйте позже.";
            })
            .finally(() => {
              this.wait = false;
            });
        } else {
          this.error = true;
          this.isemailOk = true;
          this.errorText = "Поле Email некорректно";
        }
      } else {
        this.error = true;
        this.errorText = "Заполните все поля";
      }
    },
    onReady() {
      this.$refs.youtube.playVideo();
    },
  },
  computed: {
    offset() {
      if (window.innerHeight > 1080) {
        return 250;
      } else if (window.innerWidth < 560) {
        return 100;
      } else {
        return 125;
      }
    },
    ...mapGetters(["getPopup", "getModuleStage", "getBlockState", "getOffsetScrolled"]),
    disableState() {
      return this.wait || this.success ? true : false;
    },
  },
};
</script>

<style lang="scss">

main {
  // margin-top: 125px;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.hero-screen {
  height: 100vh;
  min-height: 720px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.hero-screen_actions {
  margin-top: 50px;
}

.video-holder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 430px;
}

.company {
  margin-top: 100px;
  &-page {
    &__image-wrap {
      text-decoration: none;
      &:hover {
        img {
          filter: grayscale(0);
        }
      }
      img {
        filter: grayscale(1);
      }
    }
  }
}

.invitro {
  width: auto;
  height: 24px;
}

.prodoctorov {
  width: auto;
  height: 19px;
}

.sber {
  width: auto;
  height: 27px;
}

.c1c {
  width: auto;
  height: 38px;
}

.banner,
.cloud-solution,
.cloud-solution-banner,
.about,
.clients,
.entry {
  margin-top: 154px;
}

.losso {
  position: absolute;
  width: 180px;
  height: 100%;
  top: -24px;
  left: -43px;
  background-image: url("@/assets/image/icons/losso.svg");
  background-repeat: no-repeat;
  background-position: center;
}

.modules-choose {
  margin: 70px 0px 80px 0px;
}

.modules-view {
  position: relative;
  height: 512px;

  > .row {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
}

.modules-view-media {
  width: 100%;
  height: 370px;
  box-shadow: $primary-shadow;
}

.robot {
  position: absolute;
  right: -6%;
  bottom: 15%;
}

.banner {
  width: 100%;
  box-sizing: border-box;
  padding: 93px 0px;
  background-color: $primary;
}

.banner-align {
  text-align: center;
}

.cloud-media {
  width: 560px;
  height: 595px;
}

.cloud-solution_desc {
  width: 100%;
  height: 95px;

  > img {
    width: 95px;
    height: 100%;
  }

  p {
    margin: 0px;
  }
}

.cloud-solution_desc:not(:last-child) {
  margin-bottom: 50px;
}

.cloud-solution-banner {
  position: relative;
}

.cloud-img {
  position: absolute;
  width: 931px;
  height: 100%;
  top: -39%;
  right: -25%;
  z-index: -1;
}

.about > .secondary-h2 {
  margin-bottom: 40px;
}

.entry {
  width: 100%;
  box-sizing: border-box;
  padding: 87px 0px 126px 0px;
  background-color: $primary;
}

.company {
  .secondary-h2 {
    margin-bottom: 45px;
  }
}

.entry-media {
  width: 120%;
  height: 714px;
  border-radius: 4.8px;
  transform: translateY(25%);
}
</style>
