<template>
  <div class="popup-holder">
    <div class="popup">
      <button @click="togglePopup" class="hide-popup"></button>
      <div class="container ps-md-0">
        <div class="row">
          <div class="col-12 col-xl-8 d-flex flex-column justify-content-between">
            <h2 class="secondary-h2 m-0">
              Запишитесь на <br />
              <span class="primary-color">бесплатную</span> презентацию
            </h2>
            <p class="body-2 m-xl-0 mb-3 mt-3">
              и узнайте больше о возможностях и тарифах экосистемы MEDICI
            </p>
            <div class="form">
              <div class="form__row">
                <EntryInput v-model:entryValue="email" @update:entryValue="email = $event" placeholder="example@mail.ru"
                  type="email" id="email">Адрес электронной почты</EntryInput>
                <EntryInput v-model:entryValue="phone" @update:entryValue="phone = $event"
                  placeholder="+7 (999) 999 9999" type="phone" id="phone" maska="+7 (###) ### ####">Телефон</EntryInput>
              </div>
              <div class="form__row">
                <EntryInput v-model:entryValue="company" @update:entryValue="company = $event"
                  placeholder="ООО СибирьАптека" type="name" id="company">Название компании</EntryInput>
                <EntryInput v-model:entryValue="name" @update:entryValue="name = $event" placeholder="Иван" type="text"
                  id="username">Контактное лицо (имя)</EntryInput>
              </div>
            </div>
            <div>
              <VarButton @click="sendUser" largebutton accentbutton :wait="wait" :success="success" fw
                :disabled="disableState" class="mb-4">
                <span v-if="!(wait || success)">Записаться на презентацию</span>

              </VarButton>
              <p v-if="error" :class="[{ error: error }]">
                {{ errorText }}
              </p>
              <div class="row justify-content-center pt-2">
                <div class="col-8">
                  <p class="low-font text-center grey-text">
                    Нажимая на кнопку, вы подтверждаете, что ознакомились с политикой по
                    обработке персональных данных и даете согласие на обработку
                    персональных данных
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div class="col-4 d-none d-xl-block">
            <div class="popup-media media-holder">
              <img :src="require('@/assets/image/company/team-photo.jpg')" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapMutations } from "vuex";
import VarButton from "../button/VarButton.vue";
import EntryInput from "../inputs/EntryInput.vue";
import sendMail from "@/API/mailRequest";
import checkEmail from "@/helpers/checkValidity";
export default {
  components: {
    VarButton,
    EntryInput,
  },
  data() {
    return {
      name: "",
      company: "",
      phone: "",
      email: "",
      success: false,
      error: false,
      wait: false,
      errorText: "",
      isphoneOk: false,
      isemailOk: false,
    };
  },
  computed: {
    disableState() {
      return this.wait || this.success ? true : false;
    },
  },
  methods: {
    ...mapMutations(["togglePopup", "newClient"]),
    sendUser() {
      var client = {};
      client.name = this.name;
      client.company = this.company;
      client.phone = this.phone;
      client.email = this.email;

      this.errorText = "";
      if (client.name && client.company && client.phone && client.email) {
        if (checkEmail(client.email)) {
          this.wait = true;
          sendMail(client)
            .then((data) => {
              if (data.status == 200) {
                this.success = true;
              } else {
                this.error = true;
                this.errorText =
                  "При отправке письма произошла ошибка. Пожалуйста, проверьте e-mail и повторите ещё раз.";
              }
            })
            .then(() => {
              if (this.success && typeof window.ym !== "undefined") {
                window.ym(process.env.VUE_APP_YANDEX_METRIKA_ID, 'reachGoal', 'sendForm')
              }
            })
            .catch(() => {
              this.error = true;
              this.errorText =
                "Произошла ошибка отправки данных. Пожалуйста, обновите страницу или попробуйте позже.";
            })
            .finally(() => {
              this.wait = false;
            });
        } else {
          this.isemailOk = true;
          this.errorText = "Поле Email некорректно";
        }
      } else {
        this.error = true;
        this.errorText = "Заполните все поля";
      }
    },
  },
};
</script>
<style lang="scss">
.popup-holder {
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  opacity: 1;
  z-index: 1100;
  background: rgba(213, 213, 222, 0.6);
}

.popup {
  position: relative;
  width: 1500px;
  height: 754px;
  box-sizing: border-box;
  border-radius: 12px;
  padding: 95px 0px 95px 137px;
  background-color: #eff1f3;
  
}

@media screen and (max-width: 1600px) {
  .popup{
    transform: scale(0.8);
  }
}
@media screen and (max-width: 560px) {
  .popup{
    transform: scale(1);
  }
}



.hide-popup {
  position: absolute;
  top: 4%;
  right: 2%;
  width: 40px;
  height: 40px;
  background: none;
  border: none;
}

.hide-popup::after,
.hide-popup::before {
  content: "";
  position: absolute;
  bottom: 50%;
  width: 100%;
  height: 100%;
  border-bottom: 4px solid #c3c3c3;
  transition: all .25s ease-out;
}
.hide-popup:hover::after,
.hide-popup:hover::before {
  border-color: #457fc6;
}

.hide-popup::before {
  transform: matrix(1, -1, 0, 1, -20, 3);
}

.hide-popup::after {
  transform: matrix(1, 1, 0, -1, -20, 40);
}

.popup-media {
  width: 467px;
  height: 554px;
  border-radius: 4.8px;
}
</style>
