<template>
  <div class="user-block">
    <label :class="{ 'white-text': whitetext }" class="primary-font label-m mb-1" :for="id"
      ><slot></slot
    ></label>
    <input
      class="entry-input primary-font"
      :type="type"
      :id="id"
      :placeholder="placeholder"
      :value="entryValue"
      @input="$emit('update:entryValue',$event.target.value)"
      v-maska
      :data-maska="maska"
    />
  </div>
</template>
<script>
import { vMaska } from "maska"

export default {
  directives: { maska: vMaska },
  props: {
    maska: {
      type: String
    },
    entryValue: {
        type: String,
        default: '',
    },
    id: {
      type: String,
      default: "",
    },
    type: {
      type: String,
      default: "",
    },
    placeholder: {
      type: String,
      default: "",
    },
    whitetext: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return{};
  },
};
</script>
<style lang="scss">
.user-block {
  display: flex;
  flex-direction: column;
  > label {
    color: $primary;
  }
}
.entry-input {
  width: 100%;
  box-sizing: border-box;
  padding: 14px 0px 14px 15px;
  font-weight: 400;
  border-radius: 4px;
  border: 2px solid rgba(255, 0, 0, 0);
}

</style>
