<template>
  <div class="slider">
    <div class="slide-track">
      <template v-for="(item, i) in companyes" :key="i">
        <img :src="item" alt="" />
      </template>
    </div>
  </div>
</template>
<script>
export default {
  data() {
    return {
      companyes: [
        require("../../../public/logos-black/lastochka.png"),
        require("../../../public/logos-black/narcyss.png"),
        require("../../../public/logos-black/yazdorov.svg"),
        require("../../../public/logos-black/rzdmedicine.png"),
        require("../../../public/logos-black/ssm.png"),
        require("../../../public/logos-black/standart.png"),
        require("../../../public/logos-black/lastochka.png"),
        require("../../../public/logos-black/narcyss.png"),
        require("../../../public/logos-black/yazdorov.svg"),
        require("../../../public/logos-black/rzdmedicine.png"),
        require("../../../public/logos-black/lastochka.png"),
        require("../../../public/logos-black/narcyss.png"),
        require("../../../public/logos-black/yazdorov.svg"),
        require("../../../public/logos-black/rzdmedicine.png"),
        require("../../../public/logos-black/ssm.png"),
      ],
    };
  },
};
</script>
<style>
@import url("@/assets/styles/carousel.css");
</style>

