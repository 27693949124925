import { createRouter, createWebHistory } from 'vue-router'
import LandingPage from '@/views/landing-page'

const routes = [
   {
    path: "/",
    component: LandingPage
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
