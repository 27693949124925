<template>
  <button
    :disabled="disabled"
    class="primary-button"
    :class="{
      largebutton,
      mediumbutton,
      smallbutton,
      outlined,
      filled,
      noactive,
      playbutton,
      accentbutton,
      fw,
      fwm,
      success,
      wait,
    }"
    @click="changeMS(idStage)"
  >
    <slot>{{ text }}</slot>
    <div v-if="playbutton"></div>
  </button>
</template>
<script>
import { mapMutations } from "vuex";

export default {
  props: {
    largebutton: {
      type: Boolean,
      default: false,
    },
    mediumbutton: {
      type: Boolean,
      default: false,
    },
    smallbutton: {
      type: Boolean,
      default: false,
    },
    outlined: {
      type: Boolean,
      default: false,
    },
    filled: {
      type: Boolean,
      default: false,
    },
    noactive: {
      type: Boolean,
      default: false,
    },
    accentbutton: {
      type: Boolean,
      default: false,
    },
    playbutton: {
      type: Boolean,
      default: false,
    },
    fw: {
      type: Boolean,
      default: false,
    },
    fwm: {
      type: Boolean,
      default: false,
    },
    idStage: {
      type: Number,
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    wait: {
      type: Boolean,
      default: false,
    },
    success: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    text() {
      return this.success ? "Презентация отправлена" : this.wait ? "Отправка..." : "Отправить";
    },
  },
  methods: {
    ...mapMutations(["changeMS"]),
  },
};
</script>
<style lang="scss">
.primary-button {
  box-sizing: border-box;
  background: #fcfcfc;
  border-radius: 9px;
  padding: 10px 19px;
  transition: all 0.3s ease-in-out;
  font-family: "Neue";
}

.playbutton {
  display: flex;
  align-items: center;
  padding: 0px;
  border: none;
  background-color: $white;
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
  font-family: "Neue";
  > div {
    width: 50px;
    height: 50px;
    margin-left: 20px;
    border-radius: 50%;
    box-shadow: $primary-shadow;
    background-image: url("@/assets/image/icons/play-icon.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
}

.outlined {
  border: 2px solid $primary;
  border-bottom: 4px solid $primary;
}
.outlined:hover {
  background-color: $primary;
  color: $white;
}
.filled {
  color: #ffffff;
  background-color: #2d98e8;
  border: none;
}
.accentbutton {
  background-color: $accent-s2;
  border: none;
}
.smallbutton {
  font-family: "Neue";
  font-weight: normal;
  font-size: 16px;
  line-height: 24px;
}

.mediumbutton {
  font-family: "Neue";
  font-weight: normal;
  font-size: 18px;
  line-height: 26px;
}

.largebutton {
  font-family: "Neue";
  font-weight: normal;
  font-size: 20px;
  line-height: 28px;
}
.noactive {
  color: $primary;
  background-color: $light;
  border: none;
}

.fw {
  width: 100%;
  color: $black;
}

.wait {
  background-color: #edeff200;
  color: #5B5E6A;
}

.success {
  background-color: #8CF39D;
  color: #19161D;
}
</style>
